import { Button, Col, Form, Row, Space, theme, Table, DatePicker } from 'antd';
import React, { useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { getTopUser } from '../../services/apis';
import dayjs from 'dayjs';
import { requiredItem } from '../../constants/constants';

const ReportTopUser = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { RangePicker } = DatePicker;

    const columns = [
        {
            title: 'Vị trí xếp hạng',
            width: 90,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Nhân viên',
            dataIndex: 'user_name',
            key: 'user_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Số khách xác nhận',
            dataIndex: 'confirmed_calls',
            key: 'confirmed_calls',
            align: 'center',
            render: (text) => <div>{text}</div>
        },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getDataReport = async (startTime, endTime) => {
        const params = {
            startTime: startTime,
            endTime: endTime,
        };
        setLoading(true);
        const res = await getTopUser(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setData(res.data ? [...res.data] : []);
            
        } else {
            setData([])
        }
    }


    const onFinish = async (dataForm) => {
        getDataReport(dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
    };
    
    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                            rules={requiredItem}
                        >
                            <RangePicker
                                style={{height: 40, width: '100%'}}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                                />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportTopUser);