import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';
import ListAccountPage from './screens/mana-account/ListAccountPage';
import ImportCustomerPage from './screens/import/ImportCustomerPage';
import ReportSupportCustomer from './screens/report/ReportSupportCustomer';
import ChangePassPage from './screens/changepass/ChangePassPage';
import TelesalePage from './screens/telesale/TelesalePage';
import ListCustomerPage from './screens/list-customer/ListCustomerPage';
import ReportTopUser from './screens/report/ReportTopUser';
import ConfirmCustomerPage from './screens/confirm/ConfirmCustomerPage';
import ReportRefundPage from './screens/report/ReportRefundPage';
import ReportSuccessPage from './screens/report/ReportSuccessPage';
import { useSelector } from 'react-redux';


const AppRouter = () => {
    const userReducer = useSelector(state => state?.userReducer);
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/dang-nhap" element={
                    <LoginPage />
                } />
                <Route path="/" element={
                    <PrivateRoute>
                        {
                            userReducer?.data?.user_info?.type === "3" ? <ConfirmCustomerPage /> : <TelesalePage />
                        }
                        
                    </PrivateRoute>
                } />
                <Route path="/telesale" element={
                    <PrivateRoute>
                        <TelesalePage />
                    </PrivateRoute>
                } />
                <Route path="/import" element={
                    <PrivateRoute>
                        <ImportCustomerPage />
                    </PrivateRoute>
                } />
                <Route path="/xac-nhan" element={
                    <PrivateRoute>
                        <ConfirmCustomerPage />
                    </PrivateRoute>
                } />
                <Route path="/bao-cao-cskh" element={
                    <PrivateRoute>
                        <ReportSupportCustomer />
                    </PrivateRoute>
                } />
                <Route path="/top-saler" element={
                    <PrivateRoute>
                        <ReportTopUser />
                    </PrivateRoute>
                } />
                <Route path="/hoan-tra" element={
                    <PrivateRoute>
                        <ReportRefundPage />
                    </PrivateRoute>
                } />
                <Route path="/thanh-cong" element={
                    <PrivateRoute>
                        <ReportSuccessPage />
                    </PrivateRoute>
                } />
                <Route path="/danh-sach-khach" element={
                    <PrivateRoute>
                        <ListCustomerPage />
                    </PrivateRoute>
                } />
                
                <Route path="/quan-ly-tai-khoan" element={
                    <PrivateRoute>
                        <ListAccountPage />
                    </PrivateRoute>
                } />
                <Route path="/doi-mat-khau" element={
                    <PrivateRoute>
                        <ChangePassPage />
                    </PrivateRoute>
                } />
                
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;