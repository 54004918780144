import { Button, Col, Form, Row, Space, theme, Table, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { getListCustomerByVerifyStatus, getListUser } from '../../services/apis';
import FooterTable from '../../components/FooterTable';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { filterOption } from '../../utils/funcHelper';
import { requiredItem, STATUS_CUSTOMER, STATUS_VERIFY } from '../../constants/constants';

const ReportRefundPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const { RangePicker } = DatePicker;
    const [totalRecords, setTotalRecords] = useState(0);
    const userReducer = useSelector(state => state?.userReducer);
    const [dataUser, setDataUser] = useState([]);
    const [dataStatus, setDataStatus] = useState([
        {
            value: STATUS_CUSTOMER.CALL_SUCCESS,
            label: 'Gọi thành công'
        },
        {
            value: STATUS_CUSTOMER.CALL_BACK,
            label: 'Gọi lại'
        },
        {
            value: STATUS_CUSTOMER.CANCELLED,
            label: 'Huỷ'
        },
        {
            value: STATUS_CUSTOMER.CONFIRMED,
            label: 'Đã xác nhận'
        }
    ])
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customer_name',
            key: 'customer_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'customer_phone',
            key: 'customer_phone',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status_verify',
            key: 'status_verify',
            align: 'center',
            render: (text) => <div>{renderStatus(text)}</div>
        },
        {
            title: 'Nhân viên gọi',
            dataIndex: 'user_name',
            key: 'user_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Thời gian gọi',
            dataIndex: 'call_time',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const renderStatus = (code) => {
        let newStatus = '';
        if (code === STATUS_VERIFY.CONFIRMED) {
            newStatus = "Đã xác nhận";
        }
        if (code === STATUS_VERIFY.REFUND) {
            newStatus = "Đơn hoàn";
        }
        if (code === STATUS_VERIFY.SHIPPING) {
            newStatus = "Đang vận chuyển";
        }
        if (code === STATUS_VERIFY.SUCCESS) {
            newStatus = "Đơn thành công";
        }
        if (!code) {
            newStatus = "Chờ xác nhận";
        }
        return newStatus;
    }
    const getDataReport = async (page, startTime, endTime) => {
        const params = {
            startTime: startTime,
            endTime: endTime,
            limit: 30,
            currentPage: page,
            status_verify: STATUS_VERIFY.REFUND,
            user_id: userReducer?.data?.user_info?.type !== "1" ? userReducer?.data?.user_info?.id : form.getFieldValue("staff_id")
        };
        setLoading(true);
        const res = await getListCustomerByVerifyStatus(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setData(res.data?.data ? [...res.data?.data] : []);
            setTotalPage(res?.data?.totalPages);
            setTotalRecords(res?.data?.totalRecords)
        } else {
            setData([])
            setTotalPage(0);
            setTotalRecords(0);
        }
    }


    const onFinish = async (dataForm) => {
        setCurrentPage(1);
        if (dataForm && dataForm?.date) {
            getDataReport(1, dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
        } else {
            getDataReport(1, dayjs().format("YYYY-MM-DD") + "00:00:00", dayjs().format("YYYY-MM-DD HH:mm:ss"))
        }
    };
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReport(page - 1, dayjs(form.getFieldValue("date")[0].format("YYYY-MM-DD HH:mm:ss")), dayjs(form.getFieldValue("date")[1].format("YYYY-MM-DD HH:mm:ss")));
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReport(value, dayjs(form.getFieldValue("date")[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(form.getFieldValue("date")[1]).format("YYYY-MM-DD HH:mm:ss"))
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReport(page + 1, dayjs(form.getFieldValue("date")[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(form.getFieldValue("date")[1]).format("YYYY-MM-DD HH:mm:ss"));
    }

    const getDataUser = async () => {
        setLoading(true);
        const res = await getListUser();
        setLoading(false);
        if (res && res.status === 1) {
            setDataUser([...res?.data?.data?.map(item => ({
                value: item.id,
                label: item.fullname
            }))])
        } else {
            setDataUser([]);
        }
    }

    useEffect(() => {
        getDataUser();
        if (userReducer?.data?.user_info?.type !== '1' && form) {
            form.setFieldValue('staff_id', userReducer?.data?.user_info?.id);
        }
    }, [form]);

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name={'staff_id'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn nhân viên'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataUser}
                                disabled={userReducer?.data?.user_info?.type === '1' ? false : true}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                            rules={requiredItem}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={3}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <FooterTable
                totalRecord={totalRecords}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportRefundPage);