import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Table, Upload } from "antd";
import { useEffect, useRef, useState } from "react";
import * as XLSX from 'xlsx';
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import LoadingBase from "../../components/LoadingBase";
import { importCustomer } from "../../services/apis";


const ImportCustomerPage = () => {
    const [loadingImport, setLoadingImport] = useState(false);
    const [dataImport, setDataImport] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>{text}</div>
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>{text}</div>
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>{text}</div>
        }
        ,
        {
            title: 'Số tiền',
            dataIndex: 'total',
            key: 'total',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>{text}</div>
        },
        {
            title: 'Sản phẩm',
            dataIndex: 'product',
            key: 'product',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>{text}</div>
        }

    ]

    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                if (dataParse && dataParse.length > 0) {
                    if (dataParse.length < 2501) {
                        dataParse.shift();
                        setDataImport([...dataParse.filter(item => item.length > 0).map(subArray => {
                            return {
                              fullname: subArray[0],
                              phone: subArray[1],
                              address: subArray[2],
                              total: subArray[3],
                              product: subArray[4]
                            };
                          })]);
                    } else {
                        message.warning("Số lượng import vượt quá 2500. Vui lòng xoá bớt")
                    }
                }
                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    const statusButtonSend = () => {
        return dataImport.length > 0
    }
    const handleImportData = async () => {
        if (dataImport.length === 0) {
            message.warning("Vui lòng import file có dữ liệu");
            return;
        }
        if (dataImport.length >= 2501) {
            message.warning("Dữ liệu vượt quá 2500 dòng. Vui lòng xoá bớt để import");
            return;
        }
        setLoading(true);
        const params = {
            lstCustomer: dataImport
        }
        const res = await importCustomer(params);
        setLoading(false);
        consoleLogUtil("handleImportData", res);
        if (res && res?.status === 1) {
            message.success("Import dữ liệu thành công");
            setDataImport([]);
        } else {
            message.warning("Import dữ liệu thất bại. Vui lòng thử lại");
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
                <Upload {...props}>
                    <Button icon={<UploadOutlined />} disabled={loadingImport}>Upload danh sách</Button>
                    <span style={{paddingLeft: 8}}>Số khách hàng: <strong>{dataImport.length}</strong></span>
                </Upload>
                
                <Button icon={<SendOutlined />} disabled={statusButtonSend() === false} type="primary" onClick={handleImportData}>Lưu danh sách</Button>
            </div>
            
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={dataImport}
                pagination={{
                    pageSize: 30
                }}
                rowKey={(record, index) => index}
                bordered
                rowClassName={() => 'custom-row'}
            />

            
            <LoadingBase loading={loading} />
        </div>
    );
}

export default ImportCustomerPage;
