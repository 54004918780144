import React, { useEffect, useState } from 'react';
import {
    Card,
    Col,
    Row,
    Tooltip,
    Button,
    Divider,
    Space,
    Flex,
    Segmented,
    message,
    Spin
} from "antd";
import LoadingBase from '../../components/LoadingBase';
import { getCustomerForCall, getReportDashboardByUser, updateStatusCall } from '../../services/apis';
import '../../css/listbook-page.css';
import { useDispatch, useSelector } from 'react-redux';
import { Images } from '../../assets';
import { formatNumber } from '../../utils/funcHelper';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { FORMAT_DATE, STATUS_CUSTOMER } from '../../constants/constants';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';

const DATA_DEFAULT = [
    {
        today: 0,
        title: "THÀNH CÔNG",
        icon: Images.ic_checked,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số khách gọi thành công'
    },
    {
        today: 0,
        title: "CHỜ XÁC NHẬN",
        icon: Images.ic_waitting,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số khách chờ'
    },
    {
        today: 0,
        title: "ĐÃ XÁC NHẬN",
        icon: Images.ic_approved,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số khách đã xác nhận'
    },
    {
        today: 0,
        title: "HUỶ",
        icon: Images.ic_cancelled,
        bnb: "redtext",
        classBox: 'icon-box',
        toolTip: 'Số khách huỷ'
    },

];
const TelesalePage = () => {
    const [loading, setLoading] = useState(false);
    const [loadingTop, setLoadingTop] = useState(false);
    const [dataDashboard, setDataDashboard] = useState(DATA_DEFAULT);
    const userReducer = useSelector(state => state?.userReducer);
    const [options, setOptions] = useState([
        {
            label: 'Hôm nay',
            value: 1
        },
        {
            label: 'Tuần này',
            value: 2
        },
        {
            label: 'Tháng này',
            value: 3
        },
    ]);
    const [typeTime, setTypeTime] = useState(1);
    const [dataCustomer, setDataCustomer] = useState();
    const dispatch = useDispatch();
    consoleLogUtil("userReducer", userReducer)

    const getDataCustomer = async () => {
        setLoading(true);
        const params = {
            userId: userReducer?.data?.user_info?.id
        };
        const res = await getCustomerForCall(params);
        setLoading(false);
        consoleLogUtil("getDataCustomer", res);
        if (res && res?.status === 1) {
            setDataCustomer(res?.data);
        } else {
            setDataCustomer();
            message.warning(res?.msg ?? "Không tìm thấy khách hàng phù hợp");
        }
    }

    const callApiUpdateStatus = async (status) => {
        setLoading(true);
        const params = {
            id: dataCustomer.id,
            status: status,
            assigned_to: userReducer?.data?.user_info?.id
        }
        if (status === STATUS_CUSTOMER.CALL_BACK) {
            params.last_called_user = userReducer?.data?.user_info?.id;
        }
        const res = await updateStatusCall(params);
        setLoading(false);
        consoleLogUtil("callApiUpdateStatus", res);
        if (res && res?.status === 1) {
            setDataCustomer(res?.data);
            const temp = [...dataDashboard];
            if (status === STATUS_CUSTOMER.CALL_SUCCESS) {
                temp[0].today = temp[0].today + 1;
                temp[1].today = temp[1].today + 1;
            } else if (status === STATUS_CUSTOMER.CANCELLED) {
                temp[3].today = temp[3].today + 1;
            }
            setDataDashboard([...temp]);
            message.success(res?.msg);
        } else {
            message.warning(res?.msg ?? 'Cập nhật dữ liệu thất bại, vui lòng thử lại');
        }
    }

    const getDataDashboard = async (type) => {
        setLoadingTop(true);
        let startTime;
        if (type === 1) { //chon hom nay
            startTime = moment().startOf('day').format(FORMAT_DATE.YYYYMMDDHHMMSS);
        } else if (type === 2) { //Chon tuan nay
            startTime = moment().startOf('week').format(FORMAT_DATE.YYYYMMDDHHMMSS);
        } else { //Chon thang nay
            startTime = moment().startOf('month').format(FORMAT_DATE.YYYYMMDDHHMMSS);
        }
        const params = {
            userId: userReducer?.data?.user_info?.id,
            startTime: startTime,
            endTime: moment().format(FORMAT_DATE.YYYYMMDDHHMMSS)
        }
        const res = await getReportDashboardByUser(params);
        setLoadingTop(false);
        if (res && res?.status === 1) {
            const temp = dataDashboard.map((item, index) => {
                switch (index) {
                    case 0:
                        return { ...item, today: res?.data?.successCount };
                    case 1:
                        return { ...item, today: res?.data?.pendingConfirmationCount };
                    case 2:
                        return { ...item, today: res?.data?.confirmedCount };
                    case 3:
                        return { ...item, today: res?.data?.cancelledCount };
                }
            });
            setDataDashboard([...temp]);
        }
    }

    const handleUpdateStatusCall = (status) => {
        switch (status) {
            case STATUS_CUSTOMER.CALL_SUCCESS:
                callApiUpdateStatus(STATUS_CUSTOMER.CALL_SUCCESS);
                break;

            case STATUS_CUSTOMER.CANCELLED:
                callApiUpdateStatus(STATUS_CUSTOMER.CANCELLED);
                break;
            case STATUS_CUSTOMER.CALL_BACK:
                callApiUpdateStatus(STATUS_CUSTOMER.CALL_BACK);
                break;
        }
    }
    const handleChangeTime = (value) => {
        consoleLogUtil("handleChangeTime", value);
        setTypeTime(value);
        getDataDashboard(value);
    }
    useEffect(() => {
        getDataCustomer();
        getDataDashboard(1);
    }, [])
    return (
        <div className="layout-content">
            <Flex gap="small" align="flex-start" vertical style={{ marginBottom: 8 }}>
                <Space>
                    <Segmented options={options} value={typeTime} className="custom-segmented" onChange={handleChangeTime} />
                    <Tooltip title="Refresh dữ liệu">
                        <Button size='small' shape='circle' icon={<SyncOutlined />} onClick={() => getDataDashboard(typeTime)}></Button>
                    </Tooltip>
                </Space>
            </Flex>
            <Spin spinning={loadingTop} tip="Đang tải dữ liệu...">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {dataDashboard.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}
                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span>{c.title}</span>

                                            <Tooltip placement="top" title={c.toolTip}>
                                                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{c.today}</p>
                                            </Tooltip>


                                        </Col>
                                        <Col xs={6} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                                            <div className={c.classBox}><img src={c.icon} className="img-db" /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Spin>
            <Divider>Thông tin khách đang liên hệ</Divider>

            <Row>
                <Col span={24}>
                    <Card
                        title="Thông tin khách đang chăm sóc"
                        type="inner"
                        style={{
                            marginTop: 8,
                            marginBottom: 8
                        }}
                        headStyle={{
                            backgroundColor: '#049bdc',
                            color: "#fff",
                            height: 40,
                            fontWeight: 700,
                            fontSize: 24,
                            minHeight: 45
                        }}
                    >
                        <p><span style={{ fontSize: 16, fontWeight: 600 }}>Họ tên:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 700, fontSize: 18, color: 'blue' }}>{dataCustomer?.name ?? ''}</span></p>
                        <p><span style={{ fontSize: 16, fontWeight: 600 }}>SĐT:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 700, fontSize: 18, color: 'red' }}>{dataCustomer?.phone ?? ''}</span></p>
                        <p><span style={{ fontSize: 16, fontWeight: 600 }}>Địa chỉ:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 500, fontSize: 16, color: 'black' }}>{dataCustomer?.address ?? ''}</span></p>
                        <p><span style={{ fontSize: 16, fontWeight: 600 }}>Số tiền:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 700, fontSize: 18, color: 'black' }}>{dataCustomer?.total ? formatNumber(dataCustomer?.total) : ''}</span></p>
                        <p><span style={{ fontSize: 16, fontWeight: 600 }}>Sản phẩm:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 500, fontSize: 16, color: 'black' }}>{dataCustomer?.product ?? ''}</span></p>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
                <Col span={24} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Space style={{}}>
                        <Button
                            type='primary' size='large' style={{ width: 120 }}
                            onClick={() => handleUpdateStatusCall(STATUS_CUSTOMER.CALL_SUCCESS)}
                        >Nhận</Button>
                        <Button danger type='primary' size='large' style={{ width: 120 }}
                            onClick={() => handleUpdateStatusCall(STATUS_CUSTOMER.CANCELLED)}
                        >Huỷ</Button>
                        <Button size='large' style={{ width: 120 }}
                            onClick={() => handleUpdateStatusCall(STATUS_CUSTOMER.CALL_BACK)}
                        >Gọi lại</Button>
                    </Space>
                </Col>
            </Row>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default TelesalePage;