import { Button, Input, message, Modal } from "antd"
import React, { useEffect, useState } from "react";

const ModalEdit = ({ visible, onCancel, onSave, data }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [total, setTotal] = useState('');
    const [product, setProduct] = useState('');
    
    const handleSave = () => {
        if (name.length > 0 && phone.length > 0) {
            const temp = {
                ...data,
                name,
                phone,
                address,
                total,
                product
            }
            return onSave(temp);
        }
        message.warning("Vui lòng nhập đủ tên và số điện thoại");
    };

    useEffect(() => {
        if (data) {
            setName(data?.name);
            setPhone(data?.phone);
            setAddress(data?.address);
            setTotal(data?.total);
            setProduct(data?.product);
        }
    }, [data])

    return (
        <Modal
            title={"Sửa tên"}
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Lưu
                </Button>,
            ]}
        >
            <p>Tên khách hàng</p>
            <Input
                placeholder="Tên khách hàng"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <p className="label-modal">Số điện thoại</p>
            <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
            />
            <p className="label-modal">Địa chỉ</p>
            <Input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
            />
            <p className="label-modal">Số tiền</p>
            <Input
                value={total}
                onChange={(e) => setTotal(e.target.value)}
            />
            <p className="label-modal">Sản phẩm</p>
            <Input
                value={product}
                onChange={(e) => setProduct(e.target.value)}
            />
        </Modal>
    )
}
export default React.memo(ModalEdit);