import {
    LogoutOutlined,
    MenuFoldOutlined,
    FileSearchOutlined,
    ExceptionOutlined,
    TeamOutlined,
    BarChartOutlined,
    TagOutlined,
    BookOutlined,
    ProjectOutlined,
    MenuOutlined,
    SendOutlined,
    DashboardOutlined,
    ReloadOutlined,
    ImportOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM",
    DD_MM_YYYY_FORMAT: "DD-MM-YYYY"
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}
export const STATUS_CUSTOMER = {
    PENDING: "pending",
    CALL_BACK: "call_back",
    CONFIRMED: "confirmed",
    CANCELLED: "cancelled",
    CALL_SUCCESS: 'success'
}

export const STATUS_VERIFY = {
    SHIPPING: 'shipping',
    CONFIRMED: 'confirmed',
    REFUND: 'refund',
    SUCCESS: 'success',
    WAITTING_CONFIRM: 'waiting'
}
export const LIST_MENU_SIDE_BAR = [
    
    {
        key: 'telesale',
        path: "/telesale",
        label: 'Chăm sóc khách hàng',
        icon: <SendOutlined />,
        permission: [1, 2]
    },
    {
        key: 'import',
        path: "/import",
        label: 'Import khách hàng',
        icon: <ImportOutlined />,
        permission: [1]
    },
    {
        key: 'xac-nhan',
        path: "/xac-nhan",
        label: 'Xác nhận thông tin',
        icon: <ImportOutlined />,
        permission: [1,3]
    },
    {
        key: 'danh-sach-khach',
        path: "/danh-sach-khach",
        label: 'Danh sách khách hàng',
        icon: <ImportOutlined />,
        permission: [1]
    },
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-cskh',
                path: "/bao-cao-cskh",
                label: 'Báo cáo CSKH',
                icon: <FileSearchOutlined />,
                permission: [1, 2, 3]
            },
            {
                key: 'top-saler',
                path: "/top-saler",
                label: 'Thống kê Top saler',
                icon: <DashboardOutlined />,
                permission: [1, 2, 3]
            },
            {
                key: 'hoan-tra',
                path: "/hoan-tra",
                label: 'Thống kê đơn hoàn trả',
                icon: <DashboardOutlined />,
                permission: [1, 2, 3]
            },
            {
                key: 'thanh-cong',
                path: "/thanh-cong",
                label: 'Thống kê đơn thành công',
                icon: <DashboardOutlined />,
                permission: [1, 2, 3]
            },
        ]
    },
    {
        key: 'doi-mat-khau',
        path: "/doi-mat-khau",
        label: 'Đổi mật khẩu',
        icon: <ImportOutlined />,
        permission: [1,2,3]
    },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];

export const SEX_VALUE = [
    {
        label: "Nam",
        value: "1", 
    },
    {
        label: "Nữ",
        value: "0", 
    }
];

export const TYPE_USER = [
    {
        label: "Quản trị viên",
        value: "0", 
    },
    {
        label: "Học sinh",
        value: "1", 
    },
    {
        label: "Chuyên gia",
        value: "2", 
    }
];

export const FORMAT_DATE = {
    YYYYMMDDHHMMSS: 'YYYY-MM-DD HH:mm:ss',
    YYYMMDD: 'YYYY-MM-DD',
    DDMMYYYY: 'DD/MM/YYYY',
}