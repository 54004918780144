import { Button, Col, Form, Input, Row, Space, theme, Table, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { deleteAllCustomer, getListCustomers, updateCustomerInfo } from '../../services/apis';
import ModalEdit from './component/ModalEdit';
import FooterTable from '../../components/FooterTable';
import { STATUS_CUSTOMER } from '../../constants/constants';
import { formatNumber } from '../../utils/funcHelper';

const ListCustomerPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [totalPage, setTotalPage] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        }
        ,
        {
            title: 'Số tiền',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingRight: 12, textAlign: 'end' }}>{formatNumber(text)}</div>
        },
        {
            title: 'Sản phẩm',
            dataIndex: 'product',
            ellipsis: true,
            key: 'product',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{renderStatus(text)}</div>
        },
        {
            title: 'Hành động',
            align: 'center',
            render: (text, record, index) => <a style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }} href='#' onClick={() => handleEditName(record)}>Sửa</a>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const renderStatus = (status) => {
        let nameStatus = '';
        switch (status) {
            case STATUS_CUSTOMER.PENDING:
                nameStatus = "Chờ gọi";
                break;
            case STATUS_CUSTOMER.CALL_BACK:
                nameStatus = "Gọi lại";
                break;
            case STATUS_CUSTOMER.CONFIRMED:
                nameStatus = "Đã xác nhận";
                break;
            case STATUS_CUSTOMER.CANCELLED:
                nameStatus = "Huỷ";
                break;
            case STATUS_CUSTOMER.CALL_SUCCESS:
                nameStatus = "Chờ xác nhận";
                break;
        }
        return nameStatus;
    }

    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataCustomer(page + 1);
    }

    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataCustomer(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataCustomer(value)
    }

    const handleEditName = (item) => {
        setItemSelected(item);
        setShowModal(true);
    }
    const handleOnSave = async (item) => {
        setShowModal(false);
        try {
            setLoading(true);
            const res = await updateCustomerInfo(item);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Cập nhật thành công");
                getDataCustomer(1);
            } else {
                message.warning("Cập nhật thất bại. Vui lòng thử lại");
            }
        } catch (error) {
            setLoading(false);
            message.warning("Cập nhật thất bại. Vui lòng thử lại");
        }

    }
    const getDataCustomer = async (page) => {
        try {
            const params = {
                keyword: form.getFieldValue('keyword') ?? '',
                limit: 30,
                currentPage: page
            }
            setLoading(true);
            const res = await getListCustomers(params);
            setLoading(false);
            if (res && res?.status === 1) {
                setData(res?.data?.data ? [...res?.data?.data] : []);
                setTotalPage(res?.data?.totalPages);
                setTotalRecord(res?.data?.totalRecords)
            } else {
                setData([]);
                setTotalPage(0);
                setTotalRecord(0);
            }
        } catch (error) {
            setLoading(false);
            setData([]);
            setTotalPage(0);
            setTotalRecord(0);
            message.warning("Có lỗi xảy ra vui lòng tải lại");
        }

    }

    const handleDeleteData = async () => {
        setLoading(true);
        const res = await deleteAllCustomer();
        setLoading(false);
        if (res && res?.status === 1) {
            message.success("Xoá dữ liệu thành công");
            getDataCustomer(1);
        }
    }
    const onFinish = async (dataForm) => {
        getDataCustomer(1);
    };


    useEffect(() => {
        getDataCustomer(1);
    }, [])

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Nhập từ khoá tìm kiếm'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Popconfirm
                                title="Xác nhận xoá"
                                description="Bạn có chắc chắn muốn xoá hết dữ liệu khách hàng?"
                                onConfirm={handleDeleteData}
                                okText="Có"
                                cancelText="Không"
                            >
                                <Button danger>
                                    Xoá dữ liệu khách hàng
                                </Button>
                            </Popconfirm>
                            
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 30
                }}
                rowKey={(record, index) => record.id}
                bordered
                rowClassName={() => 'custom-row'}
            />
            <FooterTable
                totalRecord={totalRecord}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />
            <ModalEdit
                visible={showModal}
                onCancel={() => setShowModal(false)}
                data={itemSelected}
                onSave={handleOnSave}
            />
        </div>
    )
}
export default React.memo(ListCustomerPage);