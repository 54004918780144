import { Button, Card, Flex, Input, message, Modal, Select } from "antd"
import React, { useEffect, useState } from "react";
import { filterOption, formatNumber } from "../../../utils/funcHelper";
import { STATUS_VERIFY } from "../../../constants/constants";

const ModalEdit = ({ visible, onCancel, onSave, data }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [statusSelect, setStatusSelect] = useState();
    const [dataStatus, setDataStatus] = useState([
        {
            value: STATUS_VERIFY.WAITTING_CONFIRM,
            label: 'Chờ xác nhận'
        },
        {
            value: STATUS_VERIFY.CONFIRMED,
            label: 'Đã xác nhận'
        },
        {
            value: STATUS_VERIFY.SUCCESS,
            label: 'Đơn thành công'
        },
        {
            value: STATUS_VERIFY.REFUND,
            label: 'Đơn hoàn'
        }
        ,
        {
            value: STATUS_VERIFY.SHIPPING,
            label: 'Đang vận chuyển'
        }
    ])
    const renderStatus = (code) => {
        let newStatus = '';
        if (code === STATUS_VERIFY.CONFIRMED) {
            newStatus = "Đã xác nhận";
        }
        if (code === STATUS_VERIFY.REFUND) {
            newStatus = "Đơn hoàn";
        }
        if (code === STATUS_VERIFY.SHIPPING) {
            newStatus = "Đang vận chuyển";
        }
        if (code === STATUS_VERIFY.SUCCESS) {
            newStatus = "Đơn thành công";
        }
        if (!code) {
            newStatus = "Chờ xác nhận";
        }
        return newStatus;
    }
    const handleSave = () => {
        if (statusSelect?.length > 0) {
            const temp = {
                ...data,
                newStatus: statusSelect
            }
            return onSave(temp);
        }
        message.warning("Vui lòng chọn trạng thái trước khi lưu");
    };
    const handleSelect = (item) => {
        setStatusSelect(item);
    }
    useEffect(() => {
        console.log("dataaaaa", data);
        if (data) {
            setName(data?.customer_name);
            setPhone(data?.customer_phone);
            setAddress(data?.customer_address);
        }
            
    }, [data])

    return (
        <Modal
            title={"Thông tin khách hàng"}
            open={visible}
            onCancel={onCancel}
            width={'55%'}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Cập nhật
                </Button>,
            ]}
        >
            <p><span style={{ fontSize: 16, fontWeight: 600 }}>Họ tên:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 700, fontSize: 18, color: 'blue' }}>{name ?? ''}</span></p>
            <p><span style={{ fontSize: 16, fontWeight: 600 }}>SĐT:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 700, fontSize: 18, color: 'red' }}>{phone ?? ''}</span></p>
            <p><span style={{ fontSize: 16, fontWeight: 600 }}>Địa chỉ:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 500, fontSize: 16, color: 'black' }}>{address ?? ''}</span></p>
            <p><span style={{ fontSize: 16, fontWeight: 600 }}>Trạng thái cũ:</span>&nbsp;&nbsp;&nbsp; <span style={{ fontWeight: 500, fontSize: 16, color: 'black' }}>{renderStatus(data?.status_verify)}</span></p>
            <Flex gap="small" align="center"  style={{ marginTop: 24 }}>
                <p style={{ fontSize: 16, fontWeight: 600 }}>Trạng thái mới:</p>
                <Select
                    showSearch
                    placeholder={'Chọn trạng thái'}
                    style={{ width: '50%', height: 40 }}
                    defaultActiveFirstOption={false}
                    filterOption={filterOption}
                    notFoundContent={null}
                    options={dataStatus}
                    onSelect={handleSelect}
                    value={statusSelect}
                />
            </Flex>
        </Modal>
    )
}
export default React.memo(ModalEdit);